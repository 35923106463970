var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DataTable',{key:"table",attrs:{"headers":_vm.headers,"items":_vm.projects,"loading":_vm.isLoading,"items-length":_vm.totalItems,"clickable":true,"name":("projectsOverview-" + _vm.projectType),"has-add-button":true,"add-button-text":_vm.$tc('newProject', 1)},on:{"optionsChanged":_vm.onOptionsChange,"rowClicked":_vm.onRowClick,"addItemClicked":_vm.addItemClicked},scopedSlots:_vm._u([{key:"top-right",fn:function(){return [_c('v-checkbox',{staticClass:"align-center justify-center",attrs:{"hide-details":"","label":_vm.$t('showAlsoDisabled')},model:{value:(_vm.showDeactivatedProjectsLocal),callback:function ($$v) {_vm.showDeactivatedProjectsLocal=$$v},expression:"showDeactivatedProjectsLocal"}})]},proxy:true},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" "),(item.isDisabled)?_c('v-chip',{staticClass:"text-uppercase ml-2",attrs:{"small":"","text-color":"white","color":"black"},domProps:{"textContent":_vm._s(_vm.$tc('deactivated'))}}):_vm._e()]}},{key:"item.projectContact.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.projectContact.name)+" ")]}},{key:"item.projectRating",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.readableProjectRating(item.projectRating))+" ")]}},{key:"item.projectContact.owner.firstName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"tooltip"},[_vm._v(" "+_vm._s(_vm.contactFullName(item.contact))+_vm._s(_vm.getOtherContacts(item))+" ")]),(
        item.projectContact.contacts &&
        item.projectContact.contacts.length > 1
      )?_c('div',{staticClass:"tooltiptext"},_vm._l((item.projectContact.contacts.filter(
          function (item, index) { return index !== 0; }
        )),function(contact){return _c('div',{key:contact.id},[_vm._v(" "+_vm._s(contact.firstName)+" "+_vm._s(contact.lastName)+" ")])}),0):_vm._e()]}},{key:"item.projectContact.region.name",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getRegionName(item))+" ")]}},{key:"item.consultantId",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.consultantName)+" ")]}},{key:"item.consultantSecondId",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.consultantSecondName)+" ")]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$tc(item.status))+" ")]}},{key:"item.salesPricePublished",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPrice")(item.salesPricePublished))+" ")]}},{key:"item.valuationDate",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatTimestamp")(item.valuationDate))+" ")]}},{key:"item.creationDate",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatTimestamp")(item.creationDate))+" ")]}},{key:"item.projectContact.contactBranchIds",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("pluckAndJoin")(item.projectContact.contactBranches,"name"))+" ")]}},{key:"item.projectContact.subBranchIds",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("pluckAndJoin")(item.projectContact.subBranches,"name"))+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }