import Project, { ProjectWithConsultantNames } from "@/entity/Project";
import { FilterBy, SortBy, TableQuery } from "@/components/data-table/types";
import { ListResponseEntity } from "@/services/types";
import FilteringService from "@/services/FilteringService";
import jslinq from "jslinq";
import { DEFAULT_INITIAL_PAGE, DEFAULT_ITEMS_PER_PAGE } from "@/constants";

export default class ProjectFilteringService {
  private static filter(projects: Project[], filter: FilterBy): Project[] {
    return projects.filter((project) => {
      if (
        "id" in filter &&
        !FilteringService.filterByInclude(
          (project.id as number).toString(),
          filter.id
        )
      ) {
        return false;
      }

      if (
        "projectContact.name" in filter &&
        !FilteringService.filterByInclude(
          project.projectContact.name,
          filter["projectContact.name"]
        )
      ) {
        return false;
      }

      if (
        "projectContact.owner.id" in filter &&
        !FilteringService.filterByEqual(
          (project.contact?.id as number).toString(),
          filter["projectContact.owner.id"]
        )
      ) {
        return false;
      }

      if (
        "consultantId" in filter &&
        !FilteringService.filterByEqual(
          (project.consultantId ?? 0).toString(),
          filter["consultantId"]
        )
      ) {
        return false;
      }

      if (
        "consultantSecondId" in filter &&
        !FilteringService.filterByEqual(
          (project.consultantSecondId ?? 0).toString(),
          filter["consultantSecondId"]
        )
      ) {
        return false;
      }

      if (
        "status" in filter &&
        !FilteringService.filterByEqual(project.status, filter["status"])
      ) {
        return false;
      }

      if (
        "projectContact.regionId" in filter &&
        !FilteringService.filterByEqual(
          project.projectContact.regionId?.toString() ?? "",
          filter["projectContact.regionId"]
        )
      ) {
        return false;
      }

      if (
        "salesPricePublished" in filter &&
        !FilteringService.filterByRange(
          project.salesPricePublished ?? 0,
          filter["salesPricePublished"]
        )
      ) {
        return false;
      }

      if (
        "projectContact.contactBranchIds" in filter &&
        !FilteringService.filterByBranches(
          filter["projectContact.contactBranchIds"],
          project.projectContact.contactBranches
        )
      ) {
        return false;
      }

      if (
        "projectContact.subBranchIds" in filter &&
        !FilteringService.filterByBranches(
          filter["projectContact.subBranchIds"],
          project.projectContact.subBranches
        )
      ) {
        return false;
      }

      if (
        "projectType" in filter &&
        !FilteringService.filterByEqual(
          project.projectType,
          filter["projectType"]
        )
      ) {
        return false;
      }

      if (
        "valuationDate" in filter &&
        !FilteringService.filterByDate(
          project.valuationDate ?? null,
          filter["valuationDate"]
        )
      ) {
        return false;
      }

      if (
        "valuationReason" in filter &&
        !FilteringService.filterByEqual(
          project.valuationReason ?? "",
          filter["valuationReason"]
        )
      ) {
        return false;
      }

      if (
        "projectRating" in filter &&
        !FilteringService.filterByRange(
          parseFloat(project.projectRating?.toFixed(1) ?? "0"),
          filter["projectRating"]
        )
      ) {
        return false;
      }

      if (
        "creationDate" in filter &&
        !FilteringService.filterByDate(
          project.creationDate ?? null,
          filter["creationDate"]
        )
      ) {
        return false;
      }

      return true;
    });
  }

  private static sort(projects: Project[], sort: SortBy): Project[] {
    let query = jslinq(projects);

    if ("id" in sort) {
      // @ts-ignore
      query = query[FilteringService.orderingMethodName(sort["id"])](
        (el: Project) => {
          return el.id;
        }
      );
    }
    if ("projectContact.name" in sort) {
      // @ts-ignore
      query = query[
        FilteringService.orderingMethodName(sort["projectContact.name"])
      ]((el: Project) => {
        return el?.projectContact?.name?.trim().toLowerCase() ?? "";
      });
    }

    if ("projectContact.owner.firstName" in sort) {
      // @ts-ignore
      query = query[
        FilteringService.orderingMethodName(
          sort["projectContact.owner.firstName"]
        )
      ]((el: Project) => {
        if (!el?.contact) {
          return "";
        }

        return (
          el?.contact.lastName.trim().toLowerCase() ??
          "" + " " + el?.contact.firstName.trim().toLowerCase() ??
          ""
        );
      });
    }

    if ("status" in sort) {
      // @ts-ignore
      query = query[FilteringService.orderingMethodName(sort["status"])](
        (el: Project) => {
          return el.status;
        }
      );
    }

    if ("projectContact.region.name" in sort) {
      // @ts-ignore
      query = query[
        FilteringService.orderingMethodName(sort["projectContact.region.name"])
      ]((el: Project) => {
        return el.projectContact.region?.name.trim().toLocaleLowerCase() ?? "";
      });
    }

    if ("consultantId" in sort) {
      // @ts-ignore
      query = query[FilteringService.orderingMethodName(sort["consultantId"])](
        (el: ProjectWithConsultantNames) => {
          return el.consultantName.trim().toLocaleLowerCase() ?? "";
        }
      );
    }

    if ("consultantSecondId" in sort) {
      // @ts-ignore
      query = query[
        FilteringService.orderingMethodName(sort["consultantSecondId"])
      ]((el: ProjectWithConsultantNames) => {
        return el.consultantSecondName.trim().toLocaleLowerCase() ?? "";
      });
    }

    if ("salesPricePublished" in sort) {
      // @ts-ignore
      query = query[
        FilteringService.orderingMethodName(sort["salesPricePublished"])
      ]((el: Project) => {
        return el.salesPricePublished;
      });
    }

    if ("projectContact.contactBranchIds" in sort) {
      // @ts-ignore
      query = query[
        FilteringService.orderingMethodName(
          sort["projectContact.contactBranchIds"]
        )
      ]((el: Project) => {
        return el.projectContact?.contactBranches
          ? el.projectContact?.contactBranches[0].name.trim().toLowerCase()
          : "";
      });
    }

    if ("projectContact.subBranchIds" in sort) {
      // @ts-ignore
      query = query[
        FilteringService.orderingMethodName(sort["projectContact.subBranchIds"])
      ]((el: Project) => {
        return el.projectContact?.subBranches
          ? el.projectContact?.subBranches[0].name.trim().toLowerCase()
          : "";
      });
    }

    if ("valuationReason" in sort) {
      // @ts-ignore
      query = query[
        FilteringService.orderingMethodName(sort["valuationReason"])
      ]((el: Project) => {
        return el.valuationReason;
      });
    }

    if ("creationDate" in sort) {
      // @ts-ignore
      query = query[FilteringService.orderingMethodName(sort["creationDate"])](
        (el: Project) => {
          return el.creationDate;
        }
      );
    }

    if ("valuationDate" in sort) {
      // @ts-ignore
      query = query[FilteringService.orderingMethodName(sort["valuationDate"])](
        (el: Project) => {
          return el.valuationDate;
        }
      );
    }
    if ("projectRating" in sort) {
      // @ts-ignore
      query = query[FilteringService.orderingMethodName(sort["projectRating"])](
        (el: Project) => {
          return el.projectRating;
        }
      );
    }

    if (query.toList) {
      return query.toList();
    } else {
      return [];
    }
  }

  /**
   * Sorts branches by name in project
   */
  static sortBranchesPerProject(project: Project): Project {
    const sortedBranches =
      project.projectContact?.contactBranches?.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      }) ?? null;

    return {
      ...project,
      projectContact: {
        ...project.projectContact,
        contactBranches: sortedBranches,
      },
    };
  }

  static do(
    projects: Project[],
    query: TableQuery
  ): ListResponseEntity<Project> {
    const {
      page = DEFAULT_INITIAL_PAGE,
      itemsPerPage = DEFAULT_ITEMS_PER_PAGE,
      filterBy = {},
      sortBy = {},
    } = query;

    const filteredProjects = ProjectFilteringService.filter(projects, filterBy);
    const sortedProjects = ProjectFilteringService.sort(
      filteredProjects,
      sortBy
    );
    const paginatedProjects = FilteringService.paginate(
      sortedProjects,
      page,
      itemsPerPage
    );

    return {
      content: paginatedProjects,
      page: page,
      itemsPerPage: itemsPerPage,
      totalItems: filteredProjects.length,
      totalPages: Math.abs(Math.ceil(filteredProjects.length / itemsPerPage)),
    };
  }
}
