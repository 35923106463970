













































































































































import {
  Component,
  Mixins,
  Prop,
  PropSync,
  Watch,
} from "vue-property-decorator";
import RulesMixin from "@/mixins/RulesMixin";
import DatePickerWithInput from "@/components/form/input/DatePickerWithInput.vue";
import CurrencyInput from "@/components/form/input/CurrencyInput.vue";
import { formatTimestamp } from "@/utils/date";
import TranslatedEnumListsMixin from "@/mixins/TranslatedEnumListsMixin";
import { State } from "vuex-class";
import User, { UserRole } from "@/entity/User";
import { OptionItem, ReferenceItem } from "@/services/types";
import UserService from "@/services/UserService";
import { ProjectStatusTransaction, ProjectType } from "@/entity/Project";
import moment from "moment";

@Component({
  components: {
    DatePickerWithInput,
    CurrencyInput,
  },
})
export default class ProjectFormBusinessInformation extends Mixins(
  RulesMixin,
  TranslatedEnumListsMixin
) {
  @PropSync("consultantId", { type: Number }) consultantIdLocal!: number;
  @PropSync("consultantSecondId", { type: Number })
  consultantSecondIdLocal!: number;
  @PropSync("salesPricePublished", { type: [String, Number] })
  salesPricePublishedLocal!: number | string;
  @PropSync("potentialWin", { type: [String, Number] }) potentialWinLocal!:
    | number
    | string;
  @PropSync("marketingBudget", { type: [String, Number] })
  marketingBudgetLocal!: number | string;
  @PropSync("initialCost", { type: [String, Number] }) initialCostLocal!:
    | number
    | string;
  @PropSync("startingDate", { type: [String, Number] }) startingDateLocal!:
    | string
    | number;
  @PropSync("valuationDate", { type: [String, Number] }) valuationDateLocal!:
    | string
    | number;
  @PropSync("creationDate", { type: [String, Number] }) creationDateLocal!:
    | string
    | number;
  @PropSync("valuationReason", { type: [String] })
  valuationReasonLocal!: string;
  @PropSync("status", { type: String }) statusLocal!: string;
  @PropSync("note", { type: String }) noteLocal!: string;

  @Prop({ default: false }) isEditing!: boolean;
  @Prop({ type: String, required: true }) projectType!: ProjectType;
  @Prop({ type: Boolean, required: true }) wasClosedAlready!: boolean;
  @Prop({ type: Array }) regions!: ReferenceItem[];

  users: User[] = [];
  fetchingUsers = false;
  ProjectType = ProjectType;

  @State("user", { namespace: "auth" }) loggedInUser!: User;

  formatTimestamp(value: string | null): string {
    return value === null ? "" : formatTimestamp(value);
  }

  get canChangeStatus(): boolean {
    if (this.projectType === ProjectType.valuation) {
      return this.isEditing;
    } else if (this.projectType === ProjectType.transaction) {
      if (this.wasClosedAlready) {
        return this.loggedInUser.role === UserRole.ADMIN;
      } else {
        return this.isEditing;
      }
    }

    return false;
  }

  get statusOptions(): OptionItem[] {
    let statuses = [];
    if (this.projectType === ProjectType.transaction) {
      statuses = this.translatedEnums.projectStatusesTransaction;
    } else if (this.projectType === ProjectType.valuation) {
      statuses = this.translatedEnums.projectStatusesValuation;
    } else {
      return [];
    }

    // If the project is transactional the closed status should not be enabled
    statuses = statuses.map((status) => ({
      ...status,
      disabled: false,
    }));

    // Option to close transaction project should be disabled
    // And only allowed when editing project that is closed already
    // Must be enabled so if user changes status to something different and then want to change back to closed
    if (
      this.projectType === ProjectType.transaction &&
      !this.wasClosedAlready
    ) {
      const closeIndex = statuses.findIndex(
        (status) => status.value === ProjectStatusTransaction.closed
      );

      if (closeIndex !== -1) {
        statuses.splice(closeIndex, 1, {
          ...statuses[closeIndex],
          disabled: true,
        });
      }
    }

    return statuses;
  }

  get valuationReasonOptions(): string[] {
    return ["Verkauf", "Kauf", "Intern (BT)"];
  }

  get usersWithName() {
    return this.users.filter((user) => !!user.displayName);
  }

  async fetchUsers(): Promise<void> {
    try {
      this.fetchingUsers = true;
      this.users = await UserService.findAllEnabled();
    } finally {
      this.fetchingUsers = false;
    }
  }

  @Watch("statusLocal")
  onStatusChange(value: string): void {
    if (
      this.projectType === ProjectType.transaction &&
      value === ProjectStatusTransaction.marketing
    ) {
      this.startingDateLocal = moment().format();
    }
  }

  created() {
    if (!this.isEditing) {
      this.consultantIdLocal = this.loggedInUser.id as number;
    }

    this.fetchUsers();
  }
}
