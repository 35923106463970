










export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      default: "",
    },
  },
};
