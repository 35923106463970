import { ReferenceItem } from "@/services/types";
import moment from "moment";

export default class FilteringService {
  static paginate<T>(items: T[], page: number, itemsPerPage: number): T[] {
    if (itemsPerPage === -1) {
      return items;
    }
    return items.slice((page - 1) * itemsPerPage, page * itemsPerPage);
  }

  static filterByInclude(value: string, comparator: string): boolean {
    if (!value) {
      return false;
    }

    return value.toLowerCase().includes(comparator.toLowerCase());
  }

  static filterByEqual(value: string, comparator: string): boolean {
    return value === comparator;
  }

  static filterByDate(value: number | null, comparator: string): boolean {
    return moment(value).isSame(moment(comparator.split(",")[0]), "day");
  }

  static filterByRange(value: number, rangeFilter: string): boolean {
    const [from, to] = rangeFilter.split(",");
    return value >= parseFloat(from) && value <= parseFloat(to);
  }

  static filterByBranches(
    value: string,
    branches: ReferenceItem[] | null
  ): boolean {
    if (branches === null) {
      return false;
    }
    const filterBranches = value.split(",").map((id) => parseInt(id));
    const branchIds = branches?.map((branch) => branch.id);
    return filterBranches.every((branchId) => branchIds?.includes(branchId));
  }

  static orderingMethodName(order: boolean): string {
    if (order) {
      return "orderBy";
    } else if (!order) {
      return "orderByDescending";
    }

    throw new Error("Unknown order type");
  }
}
