









































































































import { Component, Prop, Mixins, PropSync } from "vue-property-decorator";
import DataTable from "@/components/data-table/DataTable.vue";
import { ITableHeader } from "@/components/data-table/types";
import { OptionItem, ReferenceItem } from "@/services/types";
import TranslatedEnumListsMixin from "@/mixins/TranslatedEnumListsMixin";
import User from "@/entity/User";
import Project, {
  getRegionNameFromProject,
  ProjectType,
} from "@/entity/Project";
import { getContactFullName } from "@/entity/Contact";

@Component({
  components: {
    DataTable,
  },
})
export default class ProjectsDataTable extends Mixins(
  TranslatedEnumListsMixin
) {
  @Prop({ type: Array, required: true }) regions!: ReferenceItem[];
  @Prop({ type: Array, required: true }) industries!: ReferenceItem[];
  @Prop({ type: Array, required: true }) subIndustries!: ReferenceItem[];
  @Prop({ type: Array, required: true }) consultants!: User[];
  @Prop({ type: Array, required: true }) consultantsFirst!: User[];
  @Prop({ type: Array, required: true }) consultantsSecond!: User[];
  @Prop({ type: Array, required: true }) owners!: OptionItem[];
  @Prop({ type: Array, required: true }) projects!: Project[];
  @Prop({ type: Boolean, required: true }) isLoading!: boolean;
  @Prop({ type: Number, required: true }) totalItems!: number;
  @Prop({ type: Function, required: true }) onOptionsChange!: () => void;
  @Prop({ type: Function, required: true }) addItemClicked!: () => void;
  @Prop({ type: Function, required: true }) onRowClick!: () => void;
  @Prop({ type: String, required: true }) projectType!: string;

  @PropSync("showDeactivatedProjects", { type: Boolean, required: true })
  showDeactivatedProjectsLocal!: boolean;

  contactFullName = getContactFullName;
  getRegionName = getRegionNameFromProject;

  readableProjectRating(projectRating: number | null): string {
    return projectRating?.toFixed(1) ?? "";
  }

  get statusOptions(): OptionItem[] {
    if (this.projectType === ProjectType.transaction) {
      return this.translatedEnums.projectStatusesTransaction;
    } else if (this.projectType === ProjectType.valuation) {
      return this.translatedEnums.projectStatusesValuation;
    } else {
      return [];
    }
  }

  get ownerLabel(): string {
    if (this.projectType === ProjectType.transaction) {
      return this.$tc("owner", 1).toString();
    } else {
      return this.$t("client").toString();
    }
  }

  get headers(): ITableHeader[] {
    if (this.projectType === ProjectType.transaction) {
      return this.headersTransaction;
    } else if (this.projectType === ProjectType.valuation) {
      return this.headersValuation;
    } else {
      return [];
    }
  }

  getOtherContacts(item: Project) {
    if (item.projectContact?.contacts?.length > 1) {
      return ", ...";
    }
    return;
  }

  get headersTransaction(): ITableHeader[] {
    return [
      {
        text: this.$tc("id", 1),
        value: "id",
        filterType: "text",
        width: "80px",
      },
      {
        text: this.$tc("projectName", 1),
        value: "projectContact.name",
        filterType: "text",
        width: "150px",
      },
      {
        text: this.ownerLabel,
        value: "projectContact.owner.firstName",
        filterValue: "projectContact.owner.id",
        filterType: "select",
        options: this.owners,
        optionText: "fullName",
        optionValue: "id",
        width: "150px",
      },
      {
        text: this.$tc("status", 1),
        value: "status",
        filterType: "select",
        options: this.statusOptions,
        optionText: "name",
        optionValue: "value",
        width: "150px",
      },
      {
        text: `${this.$tc("consultant", 1)} 1`,
        value: "consultantId",
        filterType: "select",
        options: this.consultantsFirst,
        optionText: "displayName",
        optionValue: "id",
        width: "150px",
      },
      {
        text: `${this.$tc("consultant", 1)} 2`,
        value: "consultantSecondId",
        filterType: "select",
        options: this.consultantsSecond,
        optionText: "displayName",
        optionValue: "id",
        width: "150px",
      },
      {
        text: this.$tc("region", 1),
        value: "projectContact.region.name",
        filterValue: "projectContact.regionId",
        filterType: "select",
        options: this.regions,
        optionText: "name",
        optionValue: "id",
        width: "150px",
      },
      {
        text: this.$tc("salesPricePublished", 1),
        value: "salesPricePublished",
        filterType: "range",
        width: "150px",
      },
      {
        text: this.$tc("industry", 1),
        value: "projectContact.contactBranchIds",
        filterType: "select",
        multiselect: true,
        sortable: true,
        options: this.industries,
        optionText: "name",
        optionValue: "id",
        width: "150px",
      },
      {
        text: this.$tc("subIndustry", 1),
        value: "projectContact.subBranchIds",
        filterType: "select",
        multiselect: true,
        sortable: true,
        options: this.subIndustries,
        optionText: "name",
        optionValue: "id",
        width: "150px",
      },
    ];
  }

  get headersValuation(): ITableHeader[] {
    return [
      {
        text: this.$tc("id", 1),
        value: "id",
        filterType: "text",
        width: "80px",
      },
      {
        text: this.$tc("projectName", 1),
        value: "projectContact.name",
        filterType: "text",
        width: "150px",
      },
      {
        text: this.$tc("btScore", 1),
        value: "projectRating",
        filterType: "range",
        width: "150px",
      },
      {
        text: this.ownerLabel,
        value: "projectContact.owner.firstName",
        filterValue: "projectContact.owner.id",
        filterType: "select",
        options: this.owners,
        optionText: "fullName",
        optionValue: "id",
        width: "150px",
      },
      {
        text: this.$tc("status", 1),
        value: "status",
        filterType: "select",
        options: this.statusOptions,
        optionText: "name",
        optionValue: "value",
        width: "150px",
      },
      {
        text: `${this.$tc("consultant", 1)}`,
        value: "consultantId",
        filterType: "select",
        options: this.consultantsFirst,
        optionText: "displayName",
        optionValue: "id",
        width: "150px",
      },
      {
        text: `${this.$tc("consultant", 1)} 2`,
        value: "consultantSecondId",
        filterType: "select",
        options: this.consultantsSecond,
        optionText: "displayName",
        optionValue: "id",
        width: "150px",
      },
      {
        text: this.$tc("industry", 1),
        value: "projectContact.contactBranchIds",
        filterType: "select",
        multiselect: true,
        sortable: true,
        options: this.industries,
        optionText: "name",
        optionValue: "id",
        width: "150px",
      },
      {
        text: this.$tc("subIndustry", 1),
        value: "projectContact.subBranchIds",
        filterType: "select",
        multiselect: true,
        sortable: true,
        options: this.subIndustries,
        optionText: "name",
        optionValue: "id",
        width: "150px",
      },
      {
        text: `${this.$tc("valuationDate", 1)}`,
        value: "valuationDate",
        filterType: "date",
        width: "150px",
      },
      {
        text: `${this.$tc("valuationReason", 1)}`,
        value: "valuationReason",
        filterType: "select",
        options: this.valuationReasonOptions,
        width: "150px",
      },
      {
        text: `${this.$tc("creationDate", 1)}`,
        value: "creationDate",
        filterType: "date",
        width: "150px",
      },
    ];
  }

  get valuationReasonOptions(): string[] {
    return ["Verkauf", "Kauf", "Intern (BT)"];
  }
}
