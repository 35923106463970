






































import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { TableQuery } from "@/components/data-table/types";
import ProjectsService from "@/services/ProjectsService";
import Project, {
  ProjectType,
  ProjectWithConsultantNames,
} from "@/entity/Project";
import ProjectFormCard from "@/components/project-form/ProjectFormCard.vue";
import TranslatedEnumListsMixin from "@/mixins/TranslatedEnumListsMixin";
import DialogMixin from "@/mixins/DialogMixin";
import { State } from "vuex-class";
import ProjectFilteringService from "@/services/ProjectFilteringService";
import { ALL_ITEMS_PER_PAGE } from "@/constants";
import { OptionItem, ReferenceItem } from "@/services/types";
import User from "@/entity/User";
import UserService from "@/services/UserService";
import ProjectsDataTable from "@/components/data-tables/ProjectsDataTable.vue";

@Component({
  components: {
    ProjectFormCard,
    ProjectsDataTable,
  },
})
export default class ProjectsView extends Mixins(
  TranslatedEnumListsMixin,
  DialogMixin
) {
  @Prop({ type: String, required: true }) projectType!: ProjectType;

  projects: Project[] = [];
  allProjects: Project[] = [];
  showSkeleton = true;
  isFetchingProjects = false;
  itemsLength = 0;
  owners: OptionItem[] = [];
  consultants: User[] = [];
  consultantsFirst: User[] = [];
  consultantsSecond: User[] = [];
  options!: TableQuery;
  showDeactivatedProjects = false;

  @State("industries", { namespace: "selectOptions" })
  industries!: ReferenceItem[];
  @State("subIndustries", { namespace: "selectOptions" })
  subIndustries!: ReferenceItem[];
  @State("regions", { namespace: "selectOptions" }) regions!: ReferenceItem[];

  async created() {
    this.owners = await ProjectsService.getProjectOwners();
    this.consultants = await UserService.findAll();
    this.showSkeleton = false;
  }

  get consultantKeyMapped() {
    const consultants: { [key: string]: User } = {};

    this.consultants.forEach((consultant) => {
      consultants[(consultant.id as number).toString()] = consultant;
    });

    return consultants;
  }

  get projectsWithAdditionalData(): ProjectWithConsultantNames[] {
    return this.allProjects.map((project) => {
      return {
        ...project,
        consultantName:
          this.consultantKeyMapped[project.consultantId as number]
            ?.displayName ?? "",
        consultantSecondName:
          this.consultantKeyMapped[project.consultantSecondId as number]
            ?.displayName ?? "",
        contact:
          project.projectContact.contacts &&
          project.projectContact.contacts.length > 0
            ? project.projectContact.contacts[0]
            : null,
      };
    });
  }

  removeDuplicatesById(array: any) {
    const seenIds = new Set();
    const result = [];

    for (let obj of array) {
      if (!seenIds.has(obj.id)) {
        seenIds.add(obj.id);
        result.push(obj);
      }
    }

    return result;
  }

  async fetchProjects() {
    try {
      this.isFetchingProjects = true;
      const response = await ProjectsService.findAllWithRating(
        this.projectType,
        this.showDeactivatedProjects,
        { itemsPerPage: ALL_ITEMS_PER_PAGE }
      );
      const removedDuplicatesProjects = this.removeDuplicatesById(
        response.content
      );
      // Map all projects and sort each projects branches by alphabet
      this.allProjects = removedDuplicatesProjects.map(
        ProjectFilteringService.sortBranchesPerProject
      );
      this.itemsLength = response.totalItems;
    } catch (e) {
      this.$snackbarError(this.$tc("apiErrors.projectsList"));
    } finally {
      this.isFetchingProjects = false;
    }
  }

  goToProjectDetail(project: Project) {
    this.$router.push({
      name: "projectDetail",
      params: {
        projectId: (project.id as number).toString(),
        projectType: project.projectType,
      },
    });
  }

  handleFormSuccess(project: Project) {
    this.goToProjectDetail(project);
  }

  async getConsultantsInProjects(projects: any) {
    let consultantsFistColumn: any = [];
    let consultantsSecondColumn: any = [];
    for (const project of projects) {
      if (project.consultantName) {
        const found = consultantsFistColumn.find(
          (consultant: any) => consultant.id === project.consultantId
        );
        if (!found) {
          consultantsFistColumn.push({
            id: project.consultantId,
            displayName: project.consultantName,
          });
        }
      }
      if (project.consultantSecondName) {
        const found = consultantsSecondColumn.find(
          (consultant: any) => consultant.id === project.consultantSecondId
        );
        if (!found) {
          consultantsSecondColumn.push({
            id: project.consultantSecondId,
            displayName: project.consultantSecondName,
          });
        }
      }
    }

    this.consultantsFirst = consultantsFistColumn;
    this.consultantsSecond = consultantsSecondColumn;
  }

  async onOptionsChange(options: TableQuery) {
    this.options = options;

    if (this.allProjects.length === 0) {
      await this.fetchProjects();
    }

    const result = ProjectFilteringService.do(this.projectsWithAdditionalData, {
      ...options,
      filterBy: {
        ...options.filterBy,
        projectType: this.projectType,
      },
    });

    this.projects = result.content;
    await this.getConsultantsInProjects(this.projects);
    this.itemsLength = result.totalItems;
  }

  @Watch("showDeactivatedProjects")
  async onShowDeactivatedProjectsChange() {
    await this.fetchProjects();
    await this.onOptionsChange(this.options);
  }
}
