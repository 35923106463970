import { render, staticRenderFns } from "./ReadOnlyInput.vue?vue&type=template&id=0fb93ff8&functional=true&"
import script from "./ReadOnlyInput.vue?vue&type=script&lang=ts&"
export * from "./ReadOnlyInput.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VTextField})
