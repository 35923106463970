
































































import { Component, Mixins, Prop, PropSync } from "vue-property-decorator";
import RulesMixin from "@/mixins/RulesMixin";
import Contact, { CreateContact, initialContactData } from "@/entity/Contact";
import ReadOnlyInput from "@/components/form/input/ReadOnlyInput.vue";
import FormCard from "@/components/form/FormCard.vue";
import ContactFormContactInfo from "@/components/contact-form/ContactFormContactInfo.vue";
import ContactFormAddressInfo from "@/components/contact-form/ContactFormAddressInfo.vue";
import ContactFormBusinessInfo from "@/components/contact-form/ContactFormBusinessInfo.vue";
import BaseForm from "../form/BaseForm";
import { Address } from "@/entity/Contact";

@Component({
  components: {
    ReadOnlyInput,
    FormCard,
    ContactFormContactInfo,
    ContactFormAddressInfo,
    ContactFormBusinessInfo,
  },
})
export default class ProjectFormContactInformation extends Mixins(
  RulesMixin,
  BaseForm
) {
  @PropSync("firstName", { type: String }) firstNameLocal!: string;
  @PropSync("entityId", { type: String }) contactId!: string;
  @PropSync("lastName", { type: String }) lastNameLocal!: string;
  @PropSync("email", { type: String }) emailLocal!: string;
  @PropSync("salutationForm", { type: String }) salutationFormLocal!: string;
  @PropSync("phoneMobile", { type: String }) phoneMobileLocal!: string;
  @PropSync("phoneFixed", { type: String }) phoneFixedLocal!: string;
  @PropSync("address") addressLocal!: Address;
  @PropSync("buyerTypeId", { type: String }) buyerTypeIdLocal!: string;
  @PropSync("remarks", { type: String }) remarksLocal!: string;
  @PropSync("isBlocked", { type: Boolean }) isBlockedLocal!: boolean;
  @PropSync("name", { type: String }) nameLocal!: string;
  @PropSync("createNewOwnerContact", { type: Boolean })
  createNewOwnerContactLocal!: boolean;
  @Prop() owners!: Contact[];
  @Prop() resetContact!: () => void;
  @PropSync("ownerContactId", { type: Number })
  ownerContactIdLocal!: number;
  companyId = null;
  checked = false;

  setData(contact: Partial<CreateContact>): void {
    this.data = {
      ...initialContactData(),
      ...contact,
    };
  }

  reset() {
    this.resetContact();
    this.ownerContactIdLocal = 0;
  }
}
