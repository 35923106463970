


























































































import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import moment from "moment";
import FormCard from "@/components/form/FormCard.vue";
import BaseForm from "@/components/form/BaseForm";
import ProjectFormBusinessInformation from "@/components/project-form/ProjectFormBusinessInformation.vue";
import ProjectFormCompanyInformation from "@/components/project-form/ProjectFormCompanyInformation.vue";
import ProjectFormContactInformation from "@/components/project-form/ProjectFormContactInformation.vue";
import Project, {
  ProjectStatusValuation,
  ProjectStatusTransaction,
  ProjectType,
} from "@/entity/Project";
import ProjectsService from "@/services/ProjectsService";
import { State } from "vuex-class";
import { ReferenceItem } from "@/services/types";
import Steps from "@/components/common/Steps.vue";
import Company, {
  contactToCreateCompany,
  CreateCompany,
  initialCompanyData,
} from "@/entity/Company";
import CompanyService from "@/services/CompanyService";
import Contact, {
  contactToCreateContact,
  CreateContact,
  initialContactData,
} from "@/entity/Contact";
import ContactsService from "@/services/ContactsService";

@Component({
  components: {
    FormCard,
    ProjectFormCompanyInformation,
    ProjectFormContactInformation,
    ProjectFormBusinessInformation,
    Steps,
  },
})
export default class ProjectFormCard extends Mixins<BaseForm<Partial<Project>>>(
  BaseForm
) {
  data: Partial<Project> = {
    // Step 1
    projectContactId: null,
    teaserUrl: "",
    teaserTitle: "",
    // Step 2
    consultantId: null,
    consultantSecondId: null,
    salesPricePublished: null,
    potentialWin: null,
    marketingBudget: null,
    initialCost: null,
    startingDate: null,
    valuationDate: null,
    valuationReason: null,
    creationDate: null,
    status: undefined, // TODO: Default status depends on project type,
    // Note
    note: "",
  };
  company: CreateCompany = initialCompanyData();
  contact: CreateContact = initialContactData();

  companies: Company[] = [];
  owners: Contact[] = [];

  wasClosedAlready = false;

  ownerContactId = null;

  activeStep = 1;
  projectContact = {};

  @State("regions", { namespace: "selectOptions" }) regions!: ReferenceItem[];
  @Prop({ type: String, required: true }) projectType!: ProjectType;

  get steps(): string[] {
    return [
      this.$tc("project", 1),
      this.$tc("company", 1),
      this.$tc("owner2", 1),
    ];
  }

  async submitForm(): Promise<Project> {
    if (this.isEditing) {
      return ProjectsService.update(this.entityId, {
        project: { ...this.data, projectType: this.projectType },
        company: { ...this.company },
        contact: { ...this.contact },
      });
    } else {
      return ProjectsService.create({
        project: {
          ...this.data,
          projectType: this.projectType,
          startingDate:
            this.projectType === ProjectType.valuation
              ? new Date()
              : this.data.startingDate,
        },
        company: { ...this.company },
        contact: { ...this.contact },
      });
    }
  }

  async loadData(): Promise<void> {
    if (this.entityId) {
      const response = await ProjectsService.findOne(this.entityId);
      this.wasClosedAlready =
        response.status === ProjectStatusTransaction.closed &&
        response.projectType === ProjectType.transaction;
      this.data = response;
      if (this.data.projectContact)
        this.company = contactToCreateCompany(this.data.projectContact);
      if (this.data.projectContact?.contacts) {
        this.contact = contactToCreateContact(
          this.data.projectContact.contacts[0]
        );
      }
      if (this.contact.address === null) {
        this.contact.address = {
          address: "",
          postcode: "",
          city: "",
          country: 0,
        };
      }
      if (this.company.address === null) {
        this.company.address = {
          address: "",
          postcode: "",
          city: "",
          country: 0,
        };
      }
    }
  }

  async fetchUnusedCompanies(): Promise<void> {
    this.companies = await CompanyService.findUnusedCompanies(this.projectType);
  }

  async fetchContacts(): Promise<void> {
    this.owners = await ContactsService.findAll();
  }

  resetContact() {
    this.contact = initialContactData();
  }

  resetCompany() {
    this.company = initialCompanyData();
  }

  created() {
    this.handleFormLoad(this.loadData);

    if (this.projectType === ProjectType.valuation) {
      this.data.status = ProjectStatusValuation.evaluating;
    } else if (this.projectType === ProjectType.transaction) {
      this.data.status = ProjectStatusTransaction.preparation;
    }
    this.fetchUnusedCompanies();
    this.fetchContacts();
    if (this.data.projectContact) {
      this.company = contactToCreateCompany(this.data.projectContact);
    }
  }

  setCompanyData(company: Partial<CreateCompany>): void {
    this.company = {
      ...initialCompanyData(),
      ...company,
    };
  }

  @Watch("data.projectContactId")
  async onProjectContactChange(id: number) {
    // const existingCompany = this.companies.find((company) => company.id === id);
    const existingCompany = await CompanyService.findOne(id);

    if (existingCompany) {
      this.company = contactToCreateCompany(existingCompany);
      if (this.company.address === null) {
        this.company.address = {
          address: "",
          postcode: "",
          city: "",
          country: 0,
        };
      }
    }
  }

  @Watch("ownerContactId")
  async onProjectContactContactChange(id: number) {
    const existingContact = this.owners.find((contact) => contact.id === id);

    if (existingContact) {
      this.contact = contactToCreateContact(existingContact);
      if (this.contact.address === null) {
        this.contact.address = {
          address: "",
          postcode: "",
          city: "",
          country: 0,
        };
      }
    }
  }
}
